import React, { useEffect, useState } from 'react';
import Pagination from '../components/Pagination';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchDashboardProductDatasByEmail } from '../services/apiService';

interface TableViewLayoutProps {
    data: {
        clickable_value: any;
        clickable_link_type: any;
        headers: any[];
        values: any[];
        top: any;
        total_pages: number;
        current_page: number;
        title: string | null;
        sub_title: string;
        currentProject?: string | null;
        filter?: string;
        page?: string | null;
        closeSlide?: string | null;
    };
    handlePageChange: ((page: number) => Promise<void>);
    onRowClick?: (row: any) => void;
    deleteProject?: (row: any) => void;
    deleteSpecificEvent?: (row: any) => void;
    loadingToggle?: (row: any) => void;
    deleteSpecificEventFromSlide?: (mail: string, platform: string) => void;
    ProjectStatus?: (updateStatus: string, projectCode: string ,projectName:string) => void;
    TrailStatus?: (updateStatus: string, projectCode: string,projectName:string) => void;
    AccountEdit?: (row:any) => void;
    accountStatusUpdate?: (id:string,status:string,name:string) => void;
}

const TableViewLayout: React.FC<TableViewLayoutProps> = ({ data, handlePageChange, onRowClick , deleteProject, deleteSpecificEvent, loadingToggle , deleteSpecificEventFromSlide , ProjectStatus , AccountEdit , accountStatusUpdate, TrailStatus}) => {
    const [rowIndex,setRowIndex] = useState(-1)
    const [project,SetProject] = useState('')
    const [clickedEmail,setClickedEmail] = useState('')
    const [dataForEmailWiseData,setDataForEmailWiseData] = useState<Record<string, any>>({})
 
    useEffect(()=>{
        if(data.currentProject){
            SetProject(data.currentProject)
        }
    },[data])

    useEffect(()=>{
        setRowIndex(-1)
    },[data.filter,data.page,data.closeSlide])

    const handleRowClick = (url: string) => {
        window.location.href = url;
    };

    const copyToClipboard = (header: any) => {
        navigator.clipboard.writeText(header).then(() => {
            alert('URL copied!');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const getData = async (mail:string) => {
        loadingToggle && loadingToggle(true)
        try {
            setClickedEmail(mail)
            const result = await fetchDashboardProductDatasByEmail(String(project), mail);
            setDataForEmailWiseData(result.data);
        } catch (error: any) {
            console.log(error.response.data.message);
        }
        finally{
            loadingToggle && loadingToggle(false)
        }
    };

    return (
        <div>
            {data.title && <div className="w-full h-[10vh] p-1 flex justify-between items-center">
                <div className="w-[30%] h-full flex justify-start items-center">
                    <div>
                        <h2 className="text-2xl font-bold text-yellow-100">{data.title.replace(/[_-]/g, ' ').toUpperCase()}</h2>
                        <h5 className="text-xl font-medium text-yellow-100">{data.sub_title}</h5>
                    </div>
                </div>
                {data.top}
            </div>}
            <>
                <table className="w-full text-sm text-left text-black dark:text-black mt-2">
                    <thead className="text-xs text-black uppercase bg-gray-50 dark:text-black border-b-[15px] dark:bg-yellow-50/50 dark:border-yellow-500">
                        <tr>
                            {
                                data.headers.map((header: any, index: number) => (
                                    <th key={index} scope="col" className={`${index == 0 ? 'w-[20.3%]' : header.includes('created by') ? 'w-[19.2%]' : index == 1 ? 'w-[8.5%]' : index == 2 ? 'w-[14%]' : index == 3 ? 'w-[9.5%]' : index == 4 ? 'w-[14.5%]' : index == 5 ? 'w-[18.2%]' : 'w-[15%]'} px-6 py-3`}>
                                        {header.replace(/[_-]/g, ' ')}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(data.values && (data.values !== undefined) && (data.values !== null) && (data.values.length !== 0)) && data.values.map((value: any, index: number) => (
                            <tr
                                key={index}
                                className={`bg-white border-b-[8px] dark:bg-yellow-100 relative dark:border-yellow-500 ${data.clickable_value != null ? 'dark:hover:bg-yellow-600 cursor-pointer' : ''} `}
                                onClick={() => {

                                }}
                            >
                                {Object.keys(data.values[0]).map((header: string, headerIndex: number) => (
                                    header !== 'id' && header !== 'account_id' &&
                                    <td
                                        key={headerIndex}
                                        className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-black ${(rowIndex == index) && 'pb-[190px]'} ${header === "url" ? "truncate w-20" : ""} }`}
                                        onClick={() => {
                                            if(data.clickable_value == 'All Event Details'){
                                                if((header !== 'deleteSpecificEvent') && index !== rowIndex){
                                                    setRowIndex(index)
                                                }
                                                else{
                                                    setRowIndex(-1)
                                                }
                                            }
                                            if(header === 'deleteProj' && deleteProject){
                                                deleteProject(value)
                                            }
                                            else if(header === 'deleteSpecificEvent' && deleteSpecificEvent){
                                                deleteSpecificEvent(value.email)
                                            }
                                            else if(header === 'active_status' && ProjectStatus){
                                                ProjectStatus(value[header] !== true ? 'Deactivate' : 'Activate',value.code,value.project)
                                            }
                                            else if(header === 'trail' && TrailStatus){
                                                TrailStatus(value[header] !== true ? 'Activate' : 'Deactivate',value.code,value.project)
                                            }
                                            else if(header === 'AccountEdit' && AccountEdit){
                                                AccountEdit(value)
                                            }
                                            else if(header === 'accountStatus' && accountStatusUpdate){
                                                accountStatusUpdate(value.id,value[header] === 'Active' ? 'Deactivate' : 'Activate', value.name)
                                            }
                                            else{
                                                if (data.clickable_link_type == "dashboard" && header !== "qr" && header !== "url") {
                                                    handleRowClick(`/dashboard/projects?project=${data.clickable_value[index].name}&page=1`);
                                                } else if (data.clickable_link_type == "configurator") {
                                                    if(localStorage.getItem('expire_status') == "false"){
                                                        handleRowClick(`/configurator/form?page=project&code=${data.clickable_value[index].code}`);
                                                    }
                                                    else{
                                                        alert('Renew you licence to edit projects')
                                                    }
                                                } else if (header === "url") {
                                                    copyToClipboard(value[header])
                                                }
                                                onRowClick && onRowClick(value)
                                            }

                                            if(data.clickable_value == 'All Event Details'){
                                                getData(value.email)
                                            }
                                        }}
                                    >   
                                    {header === 'deleteProj' || header === 'deleteSpecificEvent' ?
                                        <FontAwesomeIcon icon={faTrash} className='text-sm text-red-600 bg-gray-200 p-1 rounded' />
                                        :
                                    header === 'active_status' ? 
                                        <div className='flex items-center'>
                                            <p className='me-2'>{value[header] !== true ? 'Active' : 'Expired'}</p>
                                            <button onClick={(e)=>{
                                                e.preventDefault()
                                            }} className={value[header] !== true ? 'bg-red-400 hover:bg-red-600 text-white p-1 rounded' : 'bg-green-400 hover:bg-green-600 p-1 rounded'}>{value[header] !== true ? 'Deactivate' : 'Activate'}</button>
                                        </div>
                                        :
                                    header === 'trail' && value['account_id'] === 24 ? 
                                        <div className='flex items-center'>
                                            <p className='me-2'>{value[header] !== true ? 'Not in use' : 'In use'}</p>
                                            <button onClick={(e)=>{
                                                e.preventDefault()
                                            }} className={value[header] !== true ? 'bg-green-400 hover:bg-green-600 text-white p-1 rounded' : 'bg-red-400 hover:bg-red-600 p-1 rounded'}>{value[header] !== true ? 'Start Trail' : 'End Trail'}</button>
                                        </div>
                                        :
                                    header === 'trail' && value['account_id'] !== 24 ? 
                                        <span className={`block`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            -
                                        </span>
                                        :
                                    header === 'accountStatus' ?
                                        <div className='flex items-center'>
                                            <p className='me-2'>{value[header]}</p>
                                            <button onClick={(e)=>{
                                                e.preventDefault()
                                            }} className={value[header] !== 'Inactive' ? 'bg-red-400 hover:bg-red-600 text-white p-1 rounded' : 'bg-green-400 hover:bg-green-600 p-1 rounded'}>{value[header] === 'Active' ? 'Deactivate' : 'Activate'}</button>
                                        </div>
                                        :
                                    header === 'AccountEdit' ?
                                        <div className='flex items-center'>
                                            <button onClick={(e)=>{
                                                e.preventDefault()
                                            }} className='bg-blue-400 hover:bg-blue-600 hover:text-white text-black px-2 py-1 rounded'>Edit</button>
                                        </div>
                                        :
                                    header === 'created_by' ?
                                        <div className='ps-14'>
                                            <small className={`bg-blue-500 rounded-full text-white ${value[header] && value[header].split('/')[0] !== 'null' ? 'px-1.5 py-1' : 'px-2.5 py-1'} border-2  font-bold`}>{value[header] && value[header].split('/')[0] !== 'null' && value[header].split('/')[0]}</small>
                                            <small className='mx-1 font-bold text-xl'>/</small>
                                            <small className={`bg-blue-500 rounded-full text-white ${value[header] && value[header].split('/')[1] !== 'null' ? 'px-1.5 py-1' : 'px-2.5 py-1'} border-2 border-red-500 font-bold`}>{value[header] && value[header].split('/')[1] !== 'null' && value[header].split('/')[1]}</small>
                                        </div>
                                        :
                                        <span className={`block ${header === "url" ? "truncate text-blue-800 underline w-[100px]" : "w-full"} ${header === "qr" && "w-[20px]"}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {value[header] == 'Webgl' ? 'Web AR' : (header === 'trail_period' && value['account_id'] === 24) ? value[header] : (header === 'trail_period' && value['account_id'] !== 24) ? '-' : value[header]}
                                        </span>
                                    }
                                    </td>
                                ))}
                                {
                                    (data.clickable_value == 'All Event Details' && rowIndex == index) && 
                                        <td className='absolute z-50 top-14 left-0 w-full flex justify-end overflow-hidden'>
                                            <table className='w-[100%]'>
                                                {/* <thead className='w-full'>
                                                    <tr className='text-center w-full'>
                                                        <th className='w-[20%] py-3'>Platform</th>
                                                        <th className='w-[20%]'>Total Views</th>
                                                        <th className='w-[20%]'>Total Duration(s)</th>
                                                        <th className='w-[20%]'>Last Seen</th>
                                                        <th className='w-[20%]'>Shares</th>
                                                    </tr>
                                                </thead> */}
                                                <tbody>
                                                    {
                                                        Object.keys(dataForEmailWiseData).map((list,index) => {
                                                            return  <tr className={`${(index == 0) && 'border-t-[5px]'} ${(Object.keys(dataForEmailWiseData).length-1 !== index) && 'border-b-[5px]'} w-full bg-gray-100`}>
                                                                        <td className='ps-20 text-[13px] w-[21.2%]'><FontAwesomeIcon className='me-2' icon={faArrowRight} />{list == 'Webgl' ? 'Web AR' : list}</td>
                                                                        <td className='p-2 text-[13px] w-[8.6%]'>{dataForEmailWiseData[list][0]?.views || '-'}</td>
                                                                        <td className='p-2 text-[13px] w-[14%]'>{dataForEmailWiseData[list][0]?.total_duration_seconds || '-'}</td>
                                                                        <td className='p-2 text-[13px] w-[9.5%]'>{dataForEmailWiseData[list][0]?.shares || '-'}</td>
                                                                        <td className='p-2 text-[13px] w-[14.5%]'>-</td>
                                                                        <td className='p-2 text-[13px] w-[18.2%]'>{dataForEmailWiseData[list][0]?.last_seen || '-'}</td>
                                                                        {dataForEmailWiseData[list][0] && dataForEmailWiseData[list][0].length !== 0 ?
                                                                            <td className='p-2 text-[13px] w-[15%]' onClick={()=>deleteSpecificEventFromSlide && deleteSpecificEventFromSlide(value.email,list)}><FontAwesomeIcon icon={faTrash} className='text-[13px] text-red-600 bg-gray-200 p-1 rounded' /></td>
                                                                        :
                                                                            <td className='p-2 text-[13px] w-[15%]'><FontAwesomeIcon icon={faTrash} className='text-[13px] text-gray-600 bg-gray-200 p-1 rounded' /></td>
                                                                        }
                                                                    </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
                {data.values && data.values.length <= 0 && (<div className='w-full h-[20vh] justify-center items-center flex font-medium'>Data is not available</div>)}
                <div className={`mt-3 flex justify-center items-center ${data.total_pages <= 1 ? 'hidden' : 'flex'}`}>
                    {Pagination({ data, handlePageChange })}
                </div>
            </>
        </div>
    );
}

export default TableViewLayout;